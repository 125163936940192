import Image from 'next/image'
import PropTypes from 'prop-types'

import styles from './avatar.module.scss'
import { constants } from '@/lib/constants'

export const Avatar = ({ size }) => {
  return (
    <div
      className={styles.avatar}
      style={{ height: size, width: size }}>
      <Image
        src={`${constants.imageHost.assets}/avatar/standard.png`}
        alt="Avatar"
        layout="fill"
        objectFit="cover"
      />
    </div>
  )
}

Avatar.propTypes = {
  size: PropTypes.number.isRequired,
}
